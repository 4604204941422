import React, { useEffect, useState } from "react";
import "./index.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { CardMedia } from "@mui/material";
import Login from "./routes/login";
import Register from "./routes/register";
import Home from "./routes/home";
import Profile from "./routes/profile";
import Edit from "./routes/edit";
import Terms from "./routes/terms";
import Policy from "./routes/privacyPolicy";
import Atras from "./routes/atras";
import HeaderPerfil from "./routes/HeaderPerfil";
import Comentarios from "./routes/comentarios";
import { isLoggedIn, endSession } from "./session";
import logo from "./imgs/logo.png";

const Loading = () => (
  <div className="loading-container">
    <CardMedia
      className="loading-img"
      alt="Cargando..."
      component="img"
      image={logo}
    />
  </div>
);

// Componente de ruta protegida
const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

// Componente de ruta pública (redirige a home si ya está autenticado)
const PublicRoute = ({ user, children }) => {
  if (user) {
    return <Navigate to="/home" replace />;
  }
  return children;
};

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setUser(user);
      } catch (error) {
        console.error("Error al verificar la autenticación:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await endSession();
      setUser(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        {/* Rutas públicas */}
        <Route
          path="/"
          element={
            <PublicRoute user={user}>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute user={user}>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute user={user}>
              <Register />
            </PublicRoute>
          }
        />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacyPolicy" element={<Policy />} />

        {/* Rutas protegidas */}
        <Route
          path="/home"
          element={
            <ProtectedRoute user={user}>
              <Home handleLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute user={user}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit"
          element={
            <ProtectedRoute user={user}>
              <Edit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/atras"
          element={
            <ProtectedRoute user={user}>
              <Atras />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HeaderPerfil"
          element={
            <ProtectedRoute user={user}>
              <HeaderPerfil />
            </ProtectedRoute>
          }
        />
        <Route
          path="/comentarios"
          element={
            <ProtectedRoute user={user}>
              <Comentarios />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;